$prefix : cui-;
$enable-negative-margins: true;
$mainBgColor: #ECF2F7;

/*Override breakpoints*/
$grid-breakpoints: (
  xs: 0,
  xsm: 220px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px) !default;

:root {
  --main-bg-color: #{$mainBgColor};
}

// REFCLAT BLAU
$primary: #6892F4;
$brand-primary: #6892F4;

@import 'ngx-toastr/toastr';

@import "@coreui/coreui/scss/coreui";
/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/remixicon/fonts/remixicon.css";
/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "form-control-flush";
@import "node_modules/ngx-popperjs/css/theme-dark.css";
@import "node_modules/@reflact/ngx-layout/scss/rag-layout.scss";
@import 'ngx-toastr/toastr-bs5-alert';


iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0;
}

.sticky-quicklook .quicklook-header {

  padding-top: 5px;

  .quicklook-title {
    font-weight: bold;
  }

  margin-top: 2px;

  padding-bottom: 0px !important;


}

@media (max-width: 575px) {
  .sticky-quicklook .datasupply-form-body {
    max-height: inherit;
    overflow-y: auto;
  }
}

.wrapper {
  padding-left: 0;
  padding-left: var(--cui-sidebar-occupy-start, 0
  );
background-color: #ECF2F7 !important;
transition: margin-right 0.3s linear;
margin-right: 0px !important
}

.ri-none {
  margin-left: 14px !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

* {
  font-size: small;
}


.btn-primary {
  [class^='ri-'] {
    color: #fff
  }
}

h4,
.h4 {
  font-size: 1.5rem;
  padding-top: 1rem;
}

.tooltip {
  left: 0.8% !important;
}

body,
app-dashboard,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $mainBgColor;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
}

.app-body .main {
  flex: 1;
  min-width: 0;
}

.sticky-submenu {
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 999;
}

.form-floating .ng-select.ng-select-single .ng-select-container {
  height: 20px;
}

.form-floating .ng-select .ng-select-container {
  min-height: 20px;
  border: none;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #D9D8Df !important;
}

.form-floating .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 0;
}

.form-floating>.ng-select.form-control,
.form-floating>.form-select {
  min-height: calc(3.5rem + 2px) !important;
  height: inherit !important;
  line-height: 1.25;
}

.ng-select [class^="ri"] {
  position: relative;
  top: 3px;
}

.sticky-quicklook .datasupply-form-body {
  border-top: 0px solid #cfd2d9;

  border-bottom: 0px solid #cfd2d9;
  padding: 2px;

  max-height: calc(100vh - 295px);
  margin-bottom: 5px;
  overflow-y: auto;
}



@media (max-width: 575px) {
  .sticky-quicklook .datasupply-form-body {
    max-height: inherit;
    overflow-y: auto;
  }
}

.table td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table th {
  border-bottom: 1px solid #000;
}

.table tr {
  border-bottom: 1px solid #EFEFEF;
}


tr.table-active,
/* .nav-link.active {
  box-shadow: -4px 0px inset orange;

}
 */
.btn-view-switcher {
  padding: 10px 20px;
  text-transform: uppercase;
  letter-spacing: 1.25px;

  border: 0px;
  border-radius: 0px;
  color: #F9F9F9;

  &:hover {

    background-color: #F9F9F9;
    color: orange;
  }

  &.btn-primary {
    background-color: #fff;

    color: orange;
    box-shadow: 0px -4px inset orange;
  }
}

.card-headline-over-switcher {
  padding-left: 20px !important
}

/* .base-view-switcher {
  border-bottom: 1px solid #D9D8D9;
  margin-bottom: 10px;
} */


ng-dropdown-panel {
  z-index: 9999999;
}

.form-control {
  background-color: #ffffff;
}

.tooltip-arrow {
  display: none !important;
}

.text-orange {
  color: orange;
}

.content-header {
  z-index: 1500
}


.sidebar-backdrop.show {
  z-index: 999
}

.modal {
  z-index: 3502
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* .grid-table {
  border:1px solid red;
  overflow: scroll;
  height: calc(100vh - 380px);

} */



.maincontent {
  border: 0px solid orange;
  height: 100%;
  overflow-y: scroll;
  padding-left: $spacer * 1.5;
}

.innertest {
  position: fixed;
  background-color: red;

}

.header {
  background-color: red;
}

.sticky-quicklook {
  border: 1px solid orange
}

.content-header h3 {
  margin: 0px;
}

.content-grid {

  grid-template-rows: auto 1fr;
  height: 100%;
  display: grid;
  grid-template-areas: 'header' 'content';
}

.content-grid-header {
  grid-area: header;
  top: 0px;
  background-color: #1A2244 !important;

  h3 {
    color: #fff;
  }

  .card-headline-over-switcher {
    padding: 40px;
  }
}

.content-grid-content {

  grid-area: content;
  overflow: auto;

}

.datasupply-table tr th {
  padding-left: calc($spacer * 1.5);
}

.datasupply-table tr td {
  padding-left: calc($spacer * 1.5);
}

.aside-grid {
  .table tr td {
    padding-left: calc($spacer * 1.5);
  }

  .table tr th {
    padding-left: calc($spacer * 1.5);
  }

  /* padding-left:calc($spacer * 1.5); */
}

/* .aside-grid-quicklook { padding:0px calc($spacer * 1.5);} */
.aside-grid-quicklook {
  border-left: 1px solid #CFD2D9;
}

.quicklook-header {
  border-bottom: 1px solid #000;
  padding-top: 5px;

  .quicklook-title {
    color: #141935;
  }


  padding-bottom: 0px !important;
}


.aside-grid-quicklook-form-grid-header {
  background-color: #EBEBEB;
  padding: 0px 0px 0px 0px;
}

.aside-grid-quicklook-form-grid-body .ds-ql-memu {
  background-color: #fff;
  margin-top: calc($spacer * -1.5);
  margin-left: calc($spacer * -1.5);
  margin-right: calc($spacer * -1.5);
  padding-left: calc($spacer * 0.75);
  top: calc($spacer * -1.5);
  z-index: 99;
  border-bottom: 1px solid #CFD3D9;
}

.atlwdg-trigger {
  z-index: 9999999
}

.quicklook-title {
  font-weight: bold;
  padding-left: calc($spacer * 1.5);
}

.tab-area-content-wrapper {
  padding: calc($spacer * 1.5);
}

.component-table th,
.component-table td {
  padding-left: calc($spacer * 1.5);

}

.clear-asside-padding tr td,
.clear-asside-padding tr th {
  padding-left: $spacer !important;
}

#toast-container>div {
  max-width: 500px;
  width: auto;
}

.cursor-pointer {
  cursor: pointer
}

.cursor-move {
  cursor: move
}

.bg-cta {
  background-color: #F8C474 !important;
}


.cal-day-cell svg {
  transform: scal(1.5);

}

.status-icon {
  font-size: 1.5em !important;
}

.day-bubble {
  width: 10px;

  >i {
    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff
  }
}

.ng-option {
  padding: 0px 10px !important;
}


.cal-month-view .cal-day-cell {
  min-height: auto !important;
}

.cal-month-view .cal-cell-row:hover {
  background-color: white !important;
}

/* .apexcharts-tooltip {
 border:2px solid dashed !important;
 background-color: red !important;
 transform: scale(1.5);
 position: absolute;
 z-index: 9999;


} */
.cal-day-cell {

  border: 1px solid #e1e1e1 !important;
  border-bottom: 0px !important;
}

.cal-open {
  /* border:2px dashed #c0c0c0 !important; */
  border-bottom: 0px !important;
}

.cal-open .tracktimer-on-day {
  border-radius: 10px 10px 0px 0px;
  background-color: #53ACD9 !important;
  color: #fff;
}

.cal-out-month {
  opacity: 0.2;

}

.cal-month-view .cal-days .cal-cell-row {
  border-bottom: 0px !important;
}

.cal-drag-active {
  opacity: 0.4;
}



.dragging-element {
  opacity: 1 !important;
}

.cal-draggable {
  z-index: 99999;
}

.cal-today {
  background-color: #9da5b1 !important;
}

.currentholderWrapper.hidden {

  position: relative;
  top: -10px !important;
}

.shadow-xxl {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.chart-box {
  box-shadow: 0px 1px 22px -12px #607D8B;
  background-color: #fff;

}

.aside-grid-quicklook-form-grid-body {
  box-shadow: rgb(0 0 0 / 35%) 0px 30px 36px -30px inset;
  padding: calc($spacer * 1.5);
  height: 100%;
}

.calendarStats {
  background-color: #2997CF;
  color: #fff;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  position: sticky;
  top: 0px;
  z-index: 900;
  margin-top: 0px;

  box-shadow: #26394d 0 20px 30px -10px;

}

ul.dropdown-menu {
  max-height: 60vh;
  overflow-y: auto;
}

.not-trackable {
  .ri-lock-2-fill {
    display: inline !important;
  }

  .ng-select.ng-select-opened>.ng-select-container {
    background-color: #ffabab94;
  }

  color: #e55353;

  input,
  input:active,
  input:focus,
  .ng-select-container,
  .ng-select-container:active,
  .ng-select-container:focus {

    border-color: #fff !important;
    background-color: #ffabab94;
    box-shadow: none;
  }



}

.ng-select .ng-select-container {
  min-height: 32px;
  position: relative;

}

.checktrack {
  position: relative;
  left: -20px;
  top: -2px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 32px;
}

.ff-tracktarget .ng-select-container {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-color: #b1b7c1;
  border-right: 0px;
  overflow: scroll;
}

.ff-my2userselect .ng-select-container {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-color: #b1b7c1;
  border-right: 0px;
}

.bs-0 {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.bs-1 {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.be-0 {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.be-1 {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.wrapper.notificationsActive {
  width: 25rem Im !important;
  margin-right: 25rem !important;
}


#notifybar {
  right: -25rem;
  transition: right 0.3s linear;
  left: inherit;
  z-index: 100;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 1px solid #c0c0c0;
  margin-top: 40px;
  width: 25rem !important;

}

#notifybar.notificationsActive {
  right: 0px;



}

.my2noti {
  color: #000;


}

#notifybar .base-view-switcher {
  .btn-view-switcher {
    color: initial;
  }

  background-color: #fff;
  padding-left:0px !important
}

/*
.lib-rag-ds-grid {

  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)) !important;
}*/

.bg-mainbg {
  background-color: $mainBgColor;
}


.btn-white {
  border: none !important;
  color: $primary !important;
  font-weight: bold;

  &:hover {
    font-weight: bold;
    background-color: #f1f1f1;
  }

  &:active {
    font-weight: bold;
    background-color: #e2e2e2 !important;
  }
}

body.overlay-asside-isopen {
  overflow: hidden;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: darkgray;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #669995;
}

input:disabled+.slider {
  background-color: #CFD4DA;
}

input:focus+.slider {
  box-shadow: 0 0 1px #669995;
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-control-ias,
.form-control-ias:active,
.form-control-ias:focus {
  background-color: var(--main-bg-color);
  border: 0px;
  border-radius: 0px;

  border-bottom: 1px solid var(--cui-primary);
}

.form-control.loading {
  pointer-events: none;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  animation: shine 1s linear infinite;
  background-size: 200% 100% !important;



}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.spf-hand-fill {
  display: inline-block;
  height: 15px;
  width: 13px;
  margin-right: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('assets/Hand.svg');
}