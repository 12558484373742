.form-control-flush {
  width: 100%;
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid transparent;
  outline: none;

  &:focus,
  &:active,
  &:hover {
    cursor: text;
    border-bottom: 1px solid var(--cui-primary);
    background-color: #EDF2F7;

  }




}

/* 
.dblclick-editable {
  display: block;
  border: 0px;
  width: 100%;
  margin-bottom: 12px;
  border-bottom: 1px solid $primary;
} */