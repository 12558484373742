$animationColor: var(--cui-gray-400);
$animationColor2: var(--cui-gray-500);

.fakebody {
  background-image: url('assets/logo_grey_w_text.svg'), url('assets/login_background.png');
  background-repeat: no-repeat;
  background-size: 250px, cover;
  background-position: 4% 5%, center;
  height: 100vh;
}

.box {
  overflow: hidden;
  position: relative;
  width: 380px;
  height: 420px;
  background: #fff;
  border-radius: 8px;
}

.box-large {
  height: 600px;
}

.box form {
  border-radius: 4px;
  position: absolute;
  inset: 4px;
  background-color: #fff;
  padding: 50px 40px;
  z-index: 2;
  display: flex;
  flex-direction: column;

  & .inputBox {
    position: relative;
    width: 300px;
    margin-top: 35px;

    & i {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 2px;
      background-color: var(--cui-primary);
      border-radius: 4px;
      overflow: hidden;
      pointer-events: none;
      transition: 0.5s;
    }

    & input {
      position: relative;
      width: 100%;
      z-index: 99;
      padding: 20px 10px 10px;
      background: transparent;
      outline: none;
      border: 0px;
      box-shadow: none;
      color: #232323;

      &.ng-valid~span,
      &:focus~span {
        color: var(--cui-primary);
        font-size: 1em;
        transform: translateY(-34px);
      }

      &.ng-valid~i,
      &:focus~i {
        height: 44px;
        background-color: var(--main-bg-color);
      }
    }

    & span {
      position: absolute;
      left: 0px;
      padding: 20px 10px 10px;
      pointer-events: none;
      font-size: 1em;
      letter-spacing: 0.05em;
      transition: 0.5s;
    }
  }
}

.box form .links {
  text-align: right;
  width: 300px;
  margin-top: 5px;

  & a {
    font-size: 1em;
    color: black;
  }
}